/* eslint-disable no-undef */
(function () {
  var urlParams = new URLSearchParams(window.location.search);
  var mode = urlParams.get('mode') || '';

  if (!window.checkinApp) {
    window.checkinApp = {
      registrationKey: null,
      deviceId: null,
      printers: [],
      isIPad: mode.toLowerCase() === 'ipad',
    };
  }

  window.checkinApp.setRegistrationKey = function setRegistrationKey(registrationKey) {
    window.checkinApp.isIPad = true;
    window.checkinApp.registrationKey = registrationKey;
    window.dispatchEvent(
      new CustomEvent('registrationKeyReceived', {
        detail: {
          registrationKey: registrationKey,
        },
      })
    );
    return JSON.stringify({
      isSuccess: true,
      message: `Successfully passed client key ${registrationKey} to Checkin App`,
    });
  };

  window.checkinApp.setDeviceId = function setDeviceId(deviceId) {
    window.checkinApp.deviceId = deviceId;
    window.dispatchEvent(
      new CustomEvent('deviceIdReceived', {
        detail: {
          deviceId: deviceId,
        },
      })
    );
    return JSON.stringify({
      isSuccess: true,
      message: `Successfully passed deviceId ${deviceId} to Checkin App`,
    });
  };

  window.checkinApp.setPrinters = function setPrinters(printers) {
    window.checkinApp.printers = printers;
    window.dispatchEvent(
      new CustomEvent('printersReceived', {
        detail: {
          printers: printers,
        },
      })
    );
    return JSON.stringify({
      isSuccess: true,
      message: `Successfully passed printers ${printers} to Checkin App`,
    });
  };

  window.checkinApp.setPicture = function setPicture(alias, image) {
    window.dispatchEvent(
      new CustomEvent('pictureReceived', {
        detail: {
          type: alias,
          image: image,
        },
      })
    );
    return JSON.stringify({
      isSuccess: true,
      message: `Successfully passed picture ${alias} with ${image} to Checkin App`,
    });
  };

  window.checkinApp.setTrainingCenter = function setTrainingCenter(trainingCenter) {
    window.checkinApp.trainingCenter = trainingCenter;
    window.dispatchEvent(
      new CustomEvent('trainingCenterReceived', {
        detail: trainingCenter,
      })
    );
    return JSON.stringify({
      isSuccess: true,
      message: `Successfully passed training center ${trainingCenter} to Checkin App`,
    });
  };

  window.checkinApp.setBadgeNumber = function setBadgeNumber(badgeNumber) {
    window.dispatchEvent(
      new CustomEvent('badgeNumberReceived', {
        detail: badgeNumber,
      })
    );
    return JSON.stringify({
      isSuccess: true,
      message: `Successfully passed badge code ${badgeNumber} to Checkin App`,
    });
  };

  window.checkinApp.badgePrinted = function badgePrinted(printed) {
    window.dispatchEvent(
      new CustomEvent('badgePrintedReceived', {
        detail: printed,
      })
    );
    return JSON.stringify({
      isSuccess: true,
      message: `Successfully passed badge printed ${printed} to Checkin App`,
    });
  };

  window.checkinApp.setIdCardCameraSelection = function setIdCardCameraSelection(selection) {
    window.dispatchEvent(
      new CustomEvent('idCardCameraSelectionReceived', {
        detail: selection,
      })
    );
    return JSON.stringify({
      isSuccess: true,
      message: `Successfully set Id Card Camera Selection ${selection} to Checkin App`,
    });
  };

  window.checkinApp.setDocumentScanner = function setDocumentScanner(documentScanner) {
    window.checkinApp.documentScanner = documentScanner;
    window.dispatchEvent(
      new CustomEvent('documentScannerReceived', {
        detail: documentScanner,
      })
    );
    return JSON.stringify({
      isSuccess: true,
      message: `Successfully passed document scanner ${documentScanner} to Checkin App`,
    });
  };

  window.checkinApp.setDrkComplete = function setDrkComplete() {
    window.dispatchEvent(
      new CustomEvent('setDrkComplete', {
        detail: {
          isSuccess: true,
        },
      })
    );
    return JSON.stringify({
      isSuccess: true,
      message: `Successfully send setDrkComplete to Checkin App`,
    });
  };

  window.checkinApp.getPrintersComplete = function getPrintersComplete(printers) {
    window.dispatchEvent(
      new CustomEvent('getPrintersComplete', {
        detail: {
          printers: printers,
        },
      })
    );
    return JSON.stringify({
      isSuccess: true,
      message: `Successfully send getPrintersComplete to Checkin App`,
    });
  };
})();
